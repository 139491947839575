@use '../../styles/config' as config;

.container {
    margin-bottom: 8rem;
}

.header {
    height: 5rem;
    display: flex;
    justify-content: space-between;
}

.titleWrapper {
    height: 100%;
    width: 23.4rem;
    border-bottom: 2px solid config.$primary-global-red;
}

.title {
    font-weight: bold;
    font-size: 3.2rem;
    line-height: 3.2rem;
    text-transform: uppercase;
    color: config.$primary-dark-grey-text;
}

.link {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: config.$primary-global-blue;
}

.chevron {
    width: 1.2rem;
    height: 1.8rem;
    fill: config.$primary-global-blue;
    margin-left: 11px;
    margin-top: 1px;
}

.posts {
    padding-top: 4rem;
    display: flex;
    align-items: start;

    & > div:not(:last-child) {
        margin-right: 4rem;
    }
}

.icon {
    height: 1.8rem;
    fill: config.$primary-white;
}

.iconWrapper {
    width: 3.2rem;
    height: 3.2rem;
    background-color: config.$primary-global-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}