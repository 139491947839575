@use '../../styles/config' as config;

.container {
    display: flex;
    margin-bottom: 4rem;
    
    @include config.desktop-view {
        justify-content: space-between;
        flex-direction: row;
    }
    @include config.mobile-view {
        flex-direction: column;
    }
}

.column {
    @include config.desktop-view {
        width: 48%;
    }
    @include config.mobile-view {
        width: 100%;
    }
}

.leftColumn {
    @include config.mobile-view {
        margin-bottom: 4rem;
    }
}