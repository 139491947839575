@use '../../styles/config' as config;

.container {
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: bold;
    color: config.$primary-white;
    text-transform: uppercase;
    padding-bottom: 1rem;
    font-size: 1.6rem;
}

.line {
    color: config.$primary-white;
    font-size: 1.4rem;
    text-decoration: none;
    line-height: 4rem;
    transition: color 0.2s;

    @include config.mobile-view {
        line-height: 3.2rem;
    }
}

.link {
    &:hover {
        color: config.$secondary-light-blue;
    }
}
