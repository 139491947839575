@use '../../styles/config' as config;

.container {
  max-width: 66rem;
  margin-bottom: 4rem;
}

.header {
  font-size: 3.2rem;
  line-height: 3.2rem;
  text-transform: uppercase;
  margin-bottom: 4rem;
  font-weight: bold;
}

.suggestionsTitle {
  & p {
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: .25px;
    text-transform: uppercase;
    margin-bottom: 2.4rem;
    font-weight: bold;
  }
}

.suggestion {
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: .3px;
  font-weight: 300;
}

.suggestionList {
  list-style-position: inside;
}