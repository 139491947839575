@use '../../styles/config' as config;

.chevron {
  height: 1.2rem;
  width: 1.2rem;
  fill: config.$primary-dark-grey-text;
}

.pageListItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.2rem;
  width: 3.2rem;
  list-style: none;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: config.$primary-medium-grey;
  border: 1px solid #D8D9D9;
  border-radius: 2px;
  margin-right: .8rem;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;

    & > svg {
      fill: config.$primary-medium-grey;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
}

.current {
  background-color: config.$primary-global-blue;
  color: config.$primary-white;
}