@use '../../styles/config'as config;

.container {
  position: relative;
  width: 100%;
  min-height: 58rem;

  .overlay {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 45, 80, .8);
  }
}

.content {
  display: flex;
  position: absolute;
  top: 0;
  flex-direction: column;
  padding: 15rem 2rem 10rem 4rem;
  height: 100%;
  width: 80%;

  .heroTitle {
    color: config.$primary-white;
    margin-bottom: 5.6rem;

    & h1, & h2, & h3, & h4, & h5, & h6, & p {
      font-size: 8.4rem;
      line-height: 6.7rem;
      font-weight: 700;
      text-transform: uppercase;

      @media (max-width: 1440px) {
        font-size: 6.4rem;
        line-height: 5.2rem;
      }

      @media (max-height: 450px) {
        font-size: 4.8rem;
        line-height: 4rem;
      }

      @media (max-width: 700px) {
        font-size: 4.8rem;
        line-height: 4rem;
      }
    }

    & ul {
      margin-left: 3.6rem;
      list-style-position: outside;

      & li {
        padding-left: 0.5rem;
      }
    }

    & ol {
      padding-left: 3.1rem;

      & li {
        padding-left: 1rem;
      }
    }

    & li {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 2.4rem;
      letter-spacing: 0.29px;
    }

    strong {
      color: #B2C8DB;
    }
  }

  .subTitle {
    color: config.$primary-white;

    & h1, & h2, & h3, & h4, & h5, & h6, & p {
      font-size: 2.3rem;
      line-height: 2.9rem;
      font-weight: 300;
      letter-spacing: 0.25px;

      @media (max-width: 1440px) {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }

      @media (max-height: 450px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }

    & ul {
      margin-left: 3.6rem;
      list-style-position: outside;

      & li {
        padding-left: 0.5rem;
      }
    }

    & ol {
      padding-left: 3.1rem;

      & li {
        padding-left: 1rem;
      }
    }

    & li {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 2.4rem;
      letter-spacing: 0.29px;

      @media (max-height: 450px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }

  .subTitle {
    color: config.$primary-white;

    & h1, & h2, & h3, & h4, & h5, & h6, & p {
      font-size: 2.3rem;
      line-height: 2.9rem;
      font-weight: 300;
      letter-spacing: 0.25px;

      @media (max-width: 1440px) {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }

      @media (max-height: 450px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }

    & ul {
      margin-left: 3.6rem;
      list-style-position: outside;

      & li {
        padding-left: 0.5rem;
      }
    }

    & ol {
      padding-left: 3.1rem;

      & li {
        padding-left: 1rem;
      }
    }

    & li {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 2.4rem;
      letter-spacing: 0.29px;

      @media (max-height: 450px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }

  @media (max-width: 1199px) {
    width: 60%;
  }
}