@use '../../styles/config' as config;

.title {
    font-weight: bold;
    color: config.$primary-white;
    text-transform: uppercase;
    padding-bottom: 1rem;
    font-size: 1.6rem;
}

.list {
    list-style: none;
}

.option {
    display: flex;
    align-items: center;
    line-height: 4rem;
    
    & > a,
    & > a:link,
    & > a:visited {
        color: config.$primary-white;
        text-decoration: none;
        font-size: 1.4rem;
    }

    &:hover {
        & > a,
        & > a:link,
        & > a:visited {
            transition: color 0.2s;
            color: config.$secondary-light-blue;
        }

        & > svg {
            transition: fill 0.2s;
            fill: config.$secondary-light-blue;
        }
    }



    @include config.mobile-view {
        border-bottom: 1px solid rgb(216, 217, 217);
        border-bottom: 1px solid rgba(216, 217, 217, 0.2);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
    }
}

.chevron {
    height: 1.4rem;
    width: 1.4rem;
    fill: config.$primary-white;
    margin-left: 0.75rem;
    transition: color 0.2s;
}