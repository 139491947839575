.container {
    width: 100%;
    margin-bottom: 4rem;
}

.rte {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    & h1 {
        font-size: 4.8rem;
        line-height: 4rem;
        font-weight: 700;
        letter-spacing: -0.5px;
        text-transform: uppercase;
    }

    & h2 {
        font-size: 3.2rem;
        line-height: 3.2rem;
        font-weight: 700;
        letter-spacing: -0.5px;
        text-transform: uppercase;
    }

    & h3 {
        font-size: 2.4rem;
        line-height: 2.2rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    & h4 {
        font-size: 2rem;
        line-height: 2.4rem;
        font-weight: 600;
    }

    & h5 {
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 600;
    }

    & h6 {
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 600;
    }

    & p {
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 2.4rem;
        letter-spacing: 0.25px;
    }

    & ul {
        margin-left: 3.6rem;

        & li {
            padding-left: 0.5rem;
        }
    }

    & ol {
        padding-left: 3.1rem;
        & li {
            padding-left: 1.4rem;
        }
    }

    & li {
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 2.4rem;
        letter-spacing: 0.29px;
    }

}