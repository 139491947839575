@use '../../styles/config' as config;

.container {
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 600px) {
    display: block;
    height: 100%;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8rem;

  @include config.mobile-view {
    margin-top: 4rem;
  }
}
