@use '../../styles/config' as config;

.container {
    font-size: 1.2rem;
    font-weight: 300;
    padding-bottom: 4rem;

    @include config.mobile-view {
        padding: 2.4rem 0;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    line-height: 2.4rem;
}

.link {
    text-decoration: none;
    color: config.$primary-global-blue;
}

.article {
    font-weight: 600;
    color: config.$primary-light-grey-text;
}

.chevron {
    height: 1rem;
    width: 1.2rem;
    fill: #4D4E4C;

    @include config.desktop-view {
        margin: 0 0.75rem;
    }
    @include config.mobile-view {
        margin-right: 0.75rem;
    }
}