@use '../../styles/config' as config;

.container {
  display: block;
  width: 17rem;
  margin: 0 auto;
  text-decoration: none;

  img {
    height: 16.5rem;
    width: 17rem;
  }

  .title {
    font-size: 1.4rem;
    margin-top: 1.5rem;
    color: config.$primary-dark-grey-text;
  }

  .price {
    color: config.$primary-global-red;
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 1.5rem;
  }
}

@media (max-width: 767px) {
  .container {
    margin: 0;
  }
}