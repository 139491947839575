@use '../../styles/config/' as config;

.container {
    position: relative;
    cursor: pointer;
}

.topTooltip {
    @include config.top-tooltip;
}

.leftTooltip {
    @include config.left-tooltip;
}


.container:hover .topTooltip {
    visibility: visible;
    opacity: 1;
}

.container:hover .leftTooltip {
    visibility: visible;
    opacity: 1;
}

.icon {
    height: 18px;
    width: 18px;
    fill: config.$primary-medium-grey;
}