@use '../../styles/config' as config;

.container {
    width: 100%;
}

.header {
    padding-bottom: 4rem;
    border-bottom: .2rem solid config.$primary-global-red;
}

.title {
    font-size: 4.8rem;
    line-height: 4rem;
    text-transform: uppercase;
    margin-bottom: 2.4rem;
    font-weight: 700;
}

.subTitle {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 300;
}

.ctaBtn {
    display: block;
    margin: auto;
    margin-top: 8rem;
    text-transform: uppercase;

    @include config.redButton($height: 4.8rem, $width: 17.4rem)
}

.hide {
    display: none;
}