@use '../../styles/config' as config;

.container {
    background-color: config.$primary-overlay-blue;
}

.wrapper {
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @include config.mobile-view {
        height: 8.8rem;
    }
}

.form {
    display: flex;
    align-items: center;
    background-color: config.$primary-white;
    width: 100%;
    position: relative;
}

.input {
    font-family: inherit;
    color: inherit;
    font-size: 1.75rem;
    width: 100%;
    height: 4rem;
    padding: 0.5rem 3.5rem 0.5rem 1rem;
    border: 1px solid #6CC2ED;
    border-radius: 2px;

    &:focus {
        outline: none;
    }
}

.searchResultsInput {
    padding-left: 4rem;
}

.searchButton {
    border: none;
    outline: none;
    background-color: transparent;
    transition: all 0.3s;
    height: 100%;

    position: absolute;
    top: 0;
    right: 2rem;

    @include config.mobile-view {
        right: 1rem;
    }

    &:focus {
        outline: none;
    }

    &:active {
        transform: translateY(2px);
    }
}

.searchResultsButton {
    max-width: 2rem;
    border: none;
    outline: none;
    background-color: transparent;
    transition: all 0.3s;
    height: 100%;

    position: absolute;
    top: 0;
    left: 1rem;

    &:focus {
        outline: none;
    }

    &:active {
        transform: translateY(2px);
    }
}

.icon {
    height: 1.6rem;
    width: 1.6rem;
    fill: black;
}

.autoSuggestWrapper {
    position: absolute;
    top: 4rem;
    width: 100%;
    background-color: white;
    border: 1px solid #6CC2ED;
    z-index: 2000;
    
    ul {
        list-style: none;
        li {
            span {
                display: block;
                width: 100%;
                padding: 0.5rem 1rem;
                font-size: 1.75rem;
                color: inherit;
                text-decoration: none;
                cursor: pointer;
                
                &:hover {
                    color: config.$primary-white;
                    background-color: config.$primary-global-blue;
                }
            }
        }
    }
}