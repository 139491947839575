@use '../../styles/config' as config;

.container {
    min-height: 18.5rem;
    border-top: 1px solid config.$primary-light-grey;
    border-bottom: 1px solid config.$primary-light-grey;
    padding: 3.95rem 0;
    position: relative;
}

.editButton {
    position: absolute;
    top: 0;
    right: 0;
}

.wrapper {
    min-height: 10.4rem;
    display: flex;
}

.textWrapper {
    padding: 0 2.75rem;
    width: 100%;
}

.title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0.75rem;

    @include config.mobile-view {
        @media (max-width: 600px) {
            margin-bottom: 2.25rem;
        }
    }
}

.text {
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 24px;
    letter-spacing: 0.25px;
}

.image {
    height: 8rem;
    width: 8rem;
    border: 1px solid config.$primary-light-grey;

    @include config.mobile-view {
        height: 7.8rem;
        width: 7.8rem;
    }
}