@use '../../styles/config' as config;

.container {
  min-height: 39rem;
  width: 100%;
  background-color: config.$primary-global-blue;
  padding: 4rem;

    @include config.mobile-view {
      @media (max-width: 600px) {
        height: 100%;
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
}

.header {
  color: config.$primary-white;
  font-size: 3.2rem;
  line-height: 3.2rem;
  text-transform: uppercase;
  margin-bottom: 2.4rem;
}

.topic {
  text-transform: capitalize;
  font-size: 1.6rem;
  line-height: 4rem;
  list-style-type: none;
  font-weight: 600;

  & a {
    color: config.$primary-white;
    text-decoration: none;
  }
}

.topicLink {
  transition: color 0.2s ease;
  &:hover {
    color: config.$secondary-light-blue;
  }
}