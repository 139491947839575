@use '../../styles/config' as config;

.container {
    width: 100%;
}

.contentBlock {
  background-color: rgba(228,233,231,0.5);
  margin-top: 4rem;
  padding: 4rem;

  @include config.mobile-view {
    padding: 4rem 2rem;
  }

  & > h3 {
    font-size: 2.4rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
  }

  & > p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0.25px;
    font-weight: 300;
    margin-top: 1.6rem;
  }
}

.bookmarkIcon {
    height: 1.8rem;
    width: 1.8rem;
    fill: config.$primary-medium-grey;
    margin: 0 2px;
    vertical-align: middle;
  }