@use '../../styles/config/' as config;

.container {
    position: relative;
    cursor: pointer;

    @media (max-width: 600px) {
      align-self: flex-start;
    }
}

.topTooltip {
    @include config.top-tooltip;
}

.leftTooltip {
    @include config.left-tooltip;
}


.container:hover .topTooltip {
    visibility: visible;
    opacity: 1;
}

.container:hover .leftTooltip {
    visibility: visible;
    opacity: 1;
}

.icon {
    height: 18px;
    width: 18px;
    fill: config.$primary-medium-grey;

    &.saved {
        fill: config.$primary-global-red;
    }
}

// .saved {
// }