@use '../../styles/config' as config;

// .container {
//   height: 100%;
//   background-color: #F4F6F5;
//   padding: 8rem 9rem;

//   @media (max-width: 600px) {
//     padding: 8rem 1.5rem 13rem;
//   }
// }

.imageWrapper {
  text-align: center;
  margin-bottom: 3.2rem;
}

.image {
  margin: auto;
  height: 160px;
  width: 160px;
}

.productName {
  font-size: 2rem;
  line-height: 2.4rem;
  color: config.$primary-dark-grey-text;
  text-transform: capitalize;
  margin-bottom: 2.4rem;

  @media (min-width: 642px) {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
  }
}

.productPrice, .shippingText {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 700;
}

.productPrice {
  color: config.$primary-global-red;
  margin-bottom: 1rem;
}

.productRating {
  margin-right: 1rem;
}

.product {
  height: 100%;
  // width: 28.4rem;
  padding: 1.6rem 2rem 4rem;

  @include config.mobile-view {
    // height: 42rem;
    // width: 31rem;
    padding-bottom: 0;
  }

}

.ratingsWrapper {
  display: flex;
  margin-bottom: 1rem;
}

.reviewCount {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: config.$primary-global-blue;
}

.shippingText {
  text-transform: capitalize;
  color: #71B946;
}

.starIcon {
  height: 1.9rem;
  width: 1.9rem;
  fill: config.$primary-medium-grey;
}

.fill {
  height: 1.9rem;
  width: 1.9rem;
  fill: config.$secondary-yellow;
}

.tileContainer {
  height: 44.8rem;
  width: 28.4rem;
  margin-right: 4rem;
  box-shadow: 0 .04rem 1rem .01rem rgba(0,0,0,0.1);
  background-color: #FFFFFF;

  @include config.mobile-view {
    // height: 42rem;
    width: 31rem;
    margin-right: 2.4rem;
    margin-bottom: 6rem;
  }
}

.tileLink {
  flex-shrink: 0;
  text-decoration: none;
}

.title {
  font-size: 3.2rem;
  line-height: 3.2rem;
  text-transform: uppercase;
  margin-bottom: 4rem;
}

.titleWrapper {
  height: 96px;
  margin-bottom: 24px;

  @include config.mobile-view {
    max-height: 72px;
    overflow: hidden;
    margin-bottom: 17px;
  }
}