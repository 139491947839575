@use '../../styles/config' as config;

.socialIcon {
  display: inline-block;
  height: 1.68rem;
  width: 1.68rem;
  margin: auto;
}

.container {
  width: 100%;
  margin-bottom: 8rem;
}

.editButton {
  position: relative;
}

.icon {
  height: 100%;
  width: 100%;
  fill: config.$primary-white;
  margin: auto;

}

.iconContainer {
  height: 3.9rem;
  width: 3.9rem;
  background-color: config.$primary-global-blue;
  border-radius: 50px;
  margin-right: 1.68rem;
  padding: 1.12rem;
}

.icons {
  display: flex;
}

.subTitle {
  margin-bottom: 2.4rem;

  & h1, & h2, & h3, & h4, & h5, & h6, & p {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 300;
    letter-spacing: .25px;
  }

  & ul {
      margin-left: 3.6rem;
      list-style-position: outside;

      & li {
          padding-left: 0.5rem;
      }
  }

  & ol {
      padding-left: 3.1rem;
      & li {
          padding-left: 1rem;
      }
  }

  & li {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 2.4rem;
      letter-spacing: 0.29px;
  }
}


.title {
  color: config.$primary-dark-grey-text;
  margin-bottom: 2.4rem;

  @include config.mobile-view {
    @media (max-width: 600px) {
      margin-bottom: 2.4rem;
    }
  }

  & h1, & h2, & h3, & h4, & h5, & h6, & p {
    font-size: 4.8rem;
    line-height: 4rem;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-transform: uppercase;
  }

  & ul {
      margin-left: 3.6rem;
      list-style-position: outside;

      & li {
          padding-left: 0.5rem;
      }
  }

  & ol {
      padding-left: 3.1rem;
      & li {
          padding-left: 1rem;
      }
  }

  & li {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 2.4rem;
      letter-spacing: 0.29px;
  }
}
