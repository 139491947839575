@use '../../styles/config' as config;

.container {
  height: 47.4rem;
  width: 28.6rem;
  margin-bottom: 8rem;
  margin-right: 3.8rem;

  min-height: 47.4rem;
  max-height: 47.4rem;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 642px) {
    min-height: 0;
    height: auto;
    width: 100%;
    margin-right: 0;
    padding-bottom: 4rem;
    margin-bottom: 4rem;
    border-bottom: 1px solid #D8D9D9;
  }
}

.slideView {
  border-bottom: none;
  margin-bottom: 4rem;
}

.mediaWrapper {
  height: 16rem;
  max-width: 28.4rem;
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;
  transition: transform .4s;

  &:hover {
    transform: scale(1.1);
    transform-origin: 50% 50%;
  }
}

.videoIcon {
  fill: config.$primary-white;
}

.videoIconContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 4rem;
  width: 4rem;
  background-color: config.$primary-global-red;
  border-radius: 50px;
  padding: 1.1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.date {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 700;
  margin: 2.4rem 0 1.6rem;
}

.textWrapper {
  min-height: 20.8rem;
  max-height: 20.8rem;
  overflow: hidden;
  hyphens: auto;

  @media (max-width: 642px) {
    min-height: 0;
    max-height: fit-content;
  }
}

.title {
  text-decoration: none;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 2.4rem;
  letter-spacing: -0.25px;
  color: config.$primary-dark-grey-text;
  text-transform: uppercase;

  @media (min-width: 642px) {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
  }
}

.description {
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: .25px;
  font-weight: 300;
  margin-top: 1.6rem;
  text-decoration: none;
  color: config.$primary-dark-grey-text;
  display: block;

  @media (min-width: 642px) {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.metadata {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.8rem;

  @include config.mobile-view {
    margin-top: 1.6rem;
  }
}

.categories {
  & a {
    text-decoration: none;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: config.$primary-global-blue;
    font-weight: 600;
    text-transform: capitalize;
  }
}