// PRIMARY COLORS -- GLOBAL
$primary-global-blue: #004E7D;
$primary-global-red: #D41E3D;

// PRIMARY COLORS -- FOR TEXT
$primary-dark-grey-text: #212934;
$primary-light-grey-text: #424C5B;

// PRIMARY COLORS -- OTHER
$primary-overlay-blue: #002D50;
$primary-medium-grey: #BCBEC0;
$primary-light-grey: #E4E9E7;
$primary-white: #FFFFFF;

// SECONDARY COLORS
$secondary-orange: #E25411;
$secondary-green: #71B946;
$secondary-yellow: #D89F02;
$secondary-light-blue: #6CC2ED;
