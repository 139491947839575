@use './styles/config/' as config;

.content {
    margin-top: 10rem;

    @include config.mobile-view {
        margin-top: 6.4rem;
    }
}

.custom-max-width {
    margin: 0 auto;
    max-width: config.$desktop-large-max-width;

    @media (max-width: 1440px) {
        max-width: config.$desktop-max-width;
    }

    @media (max-width: 1200px) {
        max-width: 100%;
        margin: 0 15px;
    }
}