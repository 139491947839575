@use '../../styles/config' as config;

.container {
    margin-bottom: 4rem;
}

.dateWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;
}

.date {
    font-size: 1.6rem;
    line-height: 1.8rem;
    font-weight: 600;
}

.icon {
    height: 18px;
    width: 18px;
}

.title {
    font-size: 4.8rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 40px;
    letter-spacing: -0.5px;
    margin-bottom: 1.6rem;
    overflow-wrap: break-word;
}

.topicsWrapper {
    display: flex;
    margin-bottom: 4rem;
}

.topic {
    font-size: 1.6rem;
    font-weight: 600;
    color: config.$primary-global-blue;
    text-transform: capitalize;
    text-decoration: none;

    &:not(:last-child) {
        padding-right: 3px;
    }
}

.description {
    font-size: 2.4rem;
    letter-spacing: 0.25px;
    font-weight: 300;
    line-height: 32px;
    margin-bottom: 4rem;
}

.socialWrapper {
    margin-bottom: 4rem;
}

.videoWrapper {
    position: relative;
    padding-top: 56.25%;
}

.imageWrapper {
    width: 75%;

    @media (max-width: 1440px) {
        width: 100%;
    }
}

.image {
    height: auto;
    width: 100%;
}