.container {
    width: 100%;
    margin-bottom: 4rem;
}

.columnContainer {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
}

.image {
    // width: 100%;
    max-width: 100%;
    height: auto;
}

.caption {
    font-size: 1.2rem;
    line-height: 16px;
    letter-spacing: 0.25px;
    font-weight: 600;
    margin-top: 0.9rem;
}

.editButton {
    position: relative;
}