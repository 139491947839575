@use '../../styles/config' as config;

.container {
    position: fixed;
    top: 80%;
    left: 50%;
    margin-left: -21.35rem;

    opacity: 0;
    visibility: hidden;
    transition: visibility 0.4s linear, opacity linear 0.4s;
    z-index: 5;
    
    display: flex;
    background-color: config.$primary-light-grey;

    justify-content: space-between;
    align-items: center;

    box-shadow: blur rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    width: 42.7rem;
    height: 4.8rem;
    padding: 1.2rem 2.4rem;

    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.text {
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0.25px;
}

.options {
    display: flex;
    align-items: center;
}

.undo {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: config.$primary-global-blue;
    text-transform: uppercase;
    margin-right: 2.4rem;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
        color: config.$secondary-light-blue;
    }
}

.close {
    height: 1.2rem;
    width: 1.2rem;
    cursor: pointer;
    transition: fill 0.3s;

    &:hover {
        fill: config.$secondary-light-blue;
    }
}

