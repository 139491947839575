@use '../../styles/config' as config;

.container {
  height: 32.1rem;
  width: 100%;
  background-color: config.$primary-global-blue;
  padding-top: 5.2rem;
  padding-left: 4rem;
  margin-bottom: 4rem;

  @include config.mobile-view {
    @media (max-width: 600px) {
      display: none;
    }
  }
}

.contentContainer {
  max-width: 54.5rem;
}

.ctaBtn {
  @include config.whiteButton($height: 4.8rem, $width: 17.4rem);
  line-height: 2.4rem;

  &:hover {
    cursor: pointer;
  }
}

.ctaBtn, .title {
  text-transform: uppercase;
}

.subTitle {
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: .25px;
  color: config.$primary-white;
  margin-bottom: 2.4rem;
}

.title {
  font-size: 4.8rem;
  line-height: 4rem;
  color: config.$primary-white;
  margin-bottom: 1.6rem;
  font-weight: bold;
}