@use '../../styles/config' as config;

.title {
    font-weight: bold;
    color: config.$primary-white;
    text-transform: uppercase;
    padding-bottom: 1rem;
    font-size: 1.6rem;
}

.wrapper {
    display: flex;
}

.link {
    margin: 0.5rem 1.5rem 0 0;
}

.icon {
    height: 2.5rem;
    width: 2.5rem;
    fill: config.$primary-white;
    transition: fill 0.2s;

    &:hover {
        fill: config.$secondary-light-blue;
    }
}