@use '../../styles/config' as config;

.container {
  width: 68%;

  @include config.mobile-view {
    width: 100%;
    padding-bottom: 4rem;
    border-bottom: 2px solid config.$primary-global-red;
  }
}

.title {
  font-size: 4.8rem;
  line-height: 4rem;
  text-transform: uppercase;
  margin-bottom: 2.4rem;
}

.subtitle {
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 300;
  letter-spacing: 0.25px;
}
