@use '../../styles/config' as config;

.container {
  position: relative;
  padding-top: 57%;
  min-height: 48rem;

  @media (max-width: 600px) {
    padding-top: 116%;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;

  height: 100%;
  width: 100%;
  padding: 4.8rem 2rem 5.6rem 4rem;
  display: flex;

  @media (max-width: 600px) {
    padding: 4rem 2rem;
  }

  &.gradient {
    background-color: rgba(0,45,80,.80);
  }

  &.hideOverlay {
    transition: background-color 1s ease;
    background-color: transparent;
    z-index: 0;
  }
}

.content {
  width: 82%;
  
  @media (max-width: 500px) {
    align-self: center;
    width: 100%
  }
}

.hideContent {
  display: none;
}

.editButton {
  position: relative;
}

.heroTitle {
  color: config.$primary-white;
  margin-bottom: 1.6rem;

  & h1, & h2, & h3, & h4, & h5, & h6, & p {
    font-size: 8.4rem;
    line-height: 6.7rem;
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: 1440px) {
      font-size: 6.4rem;
      line-height: 5.2rem;
    }
    @media (max-height: 450px) {
      font-size: 4.8rem;
      line-height: 4rem;
    }
    @media (max-width: 700px) {
      font-size: 4.8rem;
      line-height: 4rem;
    }
  }

  & ul {
      margin-left: 3.6rem;
      list-style-position: outside;

      & li {
          padding-left: 0.5rem;
      }
  }

  & ol {
      padding-left: 3.1rem;
      & li {
          padding-left: 1rem;
      }
  }

  & li {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 2.4rem;
      letter-spacing: 0.29px;
  }
}

.redCta {
  @include config.redButton($height: 4.8rem, $width: 18.6rem);
  text-transform: uppercase;
  margin-top: 3.2rem;

  &:hover {
    cursor: pointer;
  }

  @include config.mobile-view {
    @media (max-width: 600px) {
      margin-top: 1.6rem;
    }
  }
}

.heroTitle strong {
  color: #B2C8DB;
}

.subTitle {
  color: config.$primary-white;

  & h1, & h2, & h3, & h4, & h5, & h6, & p {
    font-size: 2.3rem;
    line-height: 2.9rem;
    font-weight: 300;
    letter-spacing: 0.25px;

    @media (max-width: 1440px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    @media (max-height: 450px) {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }

  & ul {
      margin-left: 3.6rem;
      list-style-position: outside;

      & li {
          padding-left: 0.5rem;
      }
  }

  & ol {
      padding-left: 3.1rem;
      & li {
          padding-left: 1rem;
      }
  }

  & li {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 2.4rem;
      letter-spacing: 0.29px;

      @media (max-height: 450px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
  }
}

.whiteCta {
  @include config.whiteButton($height: 4.8rem, $width: 18.6rem);
  text-transform: uppercase;
  margin-top: 3.2rem;

  &:hover {
    cursor: pointer;
  }

  @include config.mobile-view {
    @media (max-width: 600px) {
      margin-top: 1.6rem;
    }
  }
}