@use '../../styles/config' as config;

.button {
  @include config.whiteButton($height: 4.8rem, $width: 17.4rem);
  text-transform: uppercase;
  margin-top: 4rem;
}

.button, .title, .image {
  &:hover {
    cursor: pointer;
  }
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  margin-bottom: 8rem;

  @include config.mobile-view {

      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;
      height: 100%;
      margin-bottom: 4rem;
  }
}

.contentWrapper {
  align-self: auto;
  background-color: config.$primary-global-blue;
  padding: 5.8rem 4rem;
  width: 50%;

  @include config.mobile-view {

      padding: 4rem 2rem;
      order: 0;
      flex: 1 1 auto;
      align-self: auto;
      width: 100%

  }
}

.date, .title, .description {
  color: config.$primary-white
}

.date {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
}

.description {
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.25px;
  font-weight: 300;
  margin-top: 1.6rem;
}

.title {
  font-size: 3.2rem;
  line-height: 3.2rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 1.6rem;
  transition: color 0.3s ease;
  letter-spacing: -0.5px;

  &:hover {
    color: config.$secondary-light-blue;
  }
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 1.5s;

  &:hover {
    transform: scale(1.1);
    transform-origin: 50% 50%;
  }
}

.imageWrapper {
  align-self: auto;
  overflow: hidden;
  width: 50%;

  @include config.mobile-view {

      // height: 25.2rem;
      height: auto;
      width: 100%;
      
      order: 0;
      flex: 0 1 auto;
      align-self: auto;

  }
}

.link {
  text-decoration: none;
}