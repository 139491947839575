@use '../../styles/config' as config;

.container {
    width: 100%;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    z-index: 999;
    
    @include config.desktop-view {
        transition: background-color 0.3s ease;
        background-color: config.$primary-white;
        height: 10rem;
        transition: height 0.3s ease;
        
        &.dark {
            background-color: config.$primary-global-blue;
        }

        &.scrolled {
            height: 6.4rem;
        }
    }

    @include config.mobile-view {
        background-color: config.$primary-global-blue;
        height: 6.4rem;
    }

}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.logo {
    width: 22.7rem;

    &.scrolled {
        width: 18.16rem;
    }

    @include config.mobile-view {
        width: 16rem;
    }
}

.menuIcon {
    height: 1.8rem;
    width: 1.6rem;
    fill: config.$primary-white;

    &:not(:last-child) {
        margin-right: 2rem;
    }
}

.mobileMenu {
    z-index: 1000;
    background-color: config.$primary-overlay-blue;
}