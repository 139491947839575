@use '../../styles/config' as config;

.article {
  display: flex;
  padding-bottom: 2.6rem;
  border-bottom: config.$primary-light-grey .05rem solid;
  margin-bottom: 2.4rem;

  &:last-child {
    border-bottom: none;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
}

.articlesWrapper {
  width: 100%;
}

.container {
  height: fit-content;
  padding: 4rem;
  width: 39.3rem;
  margin-bottom: 4rem;

  @include config.mobile-view {
    width: 100%;
  }
}

.dark {
  background-color: #F4F6F5;
}

.date {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 600;
}

.editButton {
  position: relative;
}

.header {
  font-size: 3.2rem;
  line-height: 3.2rem;
  text-transform: uppercase;
  margin-bottom: 3.2rem;
  font-weight: 700;
}

.image {
  height: 6.5rem;
  width: 6.5rem;
  object-fit: cover;
}

.imageWrapper {
  width: 6.5rem;
  height: 6.5rem;
  margin-right: 2rem;
}

.light {
  border: 1px solid #D8D9D9;
}

.link {
  color: config.$primary-dark-grey-text;
  text-decoration: none;
  text-transform: capitalize;
}

.textWrapper {
  width: 22.8rem;
}

.title {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: .8rem;

  &:hover {
    color: config.$primary-global-blue
  }
}
