@use '../../styles/config' as config;

.container {
    height: 100%;

    @include config.mobile-view {
        height: 100vh;
        width: 100%;
        background-color: config.$primary-overlay-blue;
    }
}

.wrapper {
    list-style: none;
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
    
    @include config.mobile-view {
        flex-direction: column;
        margin: 0 auto;
        padding: 1.6rem 0;
    }
}

.option {
    display: flex;
    transition: all 0.3s ease;
    align-items: center;
    position: relative;
    
    @include config.desktop-view {
        align-self: stretch;
        padding: 0 1.6rem;
    }

    @include config.mobile-view {
        width: 100%;
        &:not(:nth-last-child(1)) {
            border-bottom: 1px solid rgb(216, 217, 217);
            border-bottom: 1px solid rgba(216, 217, 217, 0.2);
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
        }
        justify-content: space-between;
    }

    & > a,
    & > a:link,
    & > a:visited {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.6rem;

        @media (max-width: 1440px) {
            font-size: 1.4rem;
        }

        display: flex;
        align-items: center;

        @include config.desktop-view {
            color: config.$primary-global-blue;
        }

        @include config.mobile-view {
            color: config.$primary-white;
            width: 100%;
            line-height: 4.8rem;
        }
    }

    &.dark > a,
    &.dark > a:link,
    &.dark > a:visited {
        color: config.$primary-white;
    }

    &.dark:hover {
        background-color: rgba(0,45,80,0.5);
    }

    // &:not(:first-child) a {
    //     height: 100%;
    // }
}

.dropdownTab {
    position: relative;

    @include config.mobile-view {
        flex-wrap: wrap;

        & a {
            display: flex;
            justify-content: space-between;
        }
    }
}

.dropdownTab > a {
    &:hover {
        cursor: default;
    }
}

.icon,
.icon g {
    height: 1.4rem;
    width: 1.4rem;
    fill: config.$primary-global-blue;

    @include config.desktop-view {
        margin-right: 8px;
    }

    &.dark,
    &.dark g {
        fill: config.$primary-white;
    }


}

.searchIconBox {
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.6rem;
}

.searchIcon {
    height: 1.6rem;
    width: 1.6rem;
    transition: fill 0.3s;
    margin-right: 0;

    &.dark:hover {
        fill: config.$secondary-light-blue;
        cursor: pointer;
    }
}

.closeIcon {
    height: 1.4rem;
    width: 1.4rem;
    transition: fill 0.3s;
    margin-right: 0;
    &:hover {
        fill: config.$secondary-light-blue;
        cursor: pointer;
    }
}

.chevron {
    margin-left: 0.5rem;

    @include config.mobile-view {
        fill: config.$primary-white;
    }
}