@use '../../styles/config' as config;

.button {
    height: 5.6rem;
    width: 8rem;
    background-color: config.$primary-global-blue;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    fill: config.$primary-white;
    height: 22px;
    width: 26px;
}