.container {
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 4rem;
}

.player {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}