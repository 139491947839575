@use '../../styles/config' as config;

.article {
  display: flex;
  height: 24.5rem;
  border-bottom: 1px solid  #d8d9d9;
  margin-top: 4rem;

  @media (max-width: 600px) {
    display: block;
    height: 100%;
  }
}

.articleDate {
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: config.$primary-dark-grey-text;
  font-weight: 600;
}

.articleImage {
  width: 100%;
  transition: transform .4s;

  &:hover {
    transform: scale(1.1);
    transform-origin: 50% 50%;
  }
}

.articleLink {
  text-decoration: none;
}

.articleSubtext {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: config.$primary-dark-grey-text;
  font-weight: 300;
  margin-top: 1.6rem;
}

.articleTitle {
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: config.$primary-dark-grey-text;
  font-weight: 700;
  margin-top: 1.6rem;
  text-transform: uppercase;

  &:hover {
    color: config.$primary-global-blue;
  }
}

.bookmarkIcon {
  height:1.8rem;
  width: 1.4rem;
  fill: config.$primary-medium-grey;

  &:hover {
    cursor: pointer;
    fill: config.$primary-global-blue;
  }
}

.bookmarkIconContainer {
  flex: 1;
  height:1.8rem;
  width: 1.4rem;
}

.categories {
  flex: 4 1 auto;
  font-size: 1.2rem;
  line-height: 2rem;
  color: config.$primary-global-blue;
  font-weight: 600;

  @media (max-width: 600px) {
    margin-bottom: 4rem;
  }
}

.categoriesContainer {
  display: flex;
  flex-direction: row;
  margin-top: 1.6rem;
}

.categoryLink {
  text-decoration: none;
  color: config.$primary-global-blue;

  &:hover {
    color: config.$primary-dark-grey-text;
  }
}

.container {
  margin-top: 4rem;
}

.ctaBtn {
  display: block;
  margin: auto;
  margin-top: 8rem;
  text-transform: uppercase;

  @include config.redButton($height: 4.8rem, $width: 17.4rem)
}

.editButton {
  position: relative;
}

.header {
  flex: 0 1 auto;
  font-size: 3.2rem;
  line-height: 3.2rem;
  color: config.$primary-dark-grey-text;
  text-transform: uppercase;
  margin-right: 2rem;
  font-weight: 700;
}

.headerWrapper {
  display: flex;
  flex-direction: row;
}

.hide {
  display: none;
}

.imageContainer {
  width: 60%;
  max-height: 16rem;
  position: relative;
  overflow: hidden;
  margin-right: 4rem;
}

.redHeaderLine {
  flex: 1;
  height: 2px;
  width: 100%;
  background-color: config.$primary-global-red;
  margin: auto;
}

.videoIcon {
  height: 1.8rem;
  width: 1.8rem;
  fill: config.$primary-white;
}

.videoIconContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 4rem;
  width: 4rem;
  background-color: config.$primary-global-red;
  border-radius: 50px;
  padding: 1.1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}