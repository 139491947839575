@use '../../styles/config' as config;

.container {
    display: flex;
}

.icon {
    fill: config.$primary-global-blue;
    width: 19px;
    height: 19px;
}

.button {
    width: 30px;
    height: 30px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}