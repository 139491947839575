@use '../../styles/config' as config;

.container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid  #d8d9d9;
  padding: 4rem 0;

  @include config.mobile-view {
    flex-direction: column;
  }
}

.mediaWrapper {
  max-width: 28.4rem;
  // width: 28.4rem;
  width: 100%;
  height: 16rem;
  position: relative;
  overflow: hidden;
  margin-right: 4rem;

  @include config.mobile-view {
    margin-bottom: 2.4rem;
  }
}

.image {
  width: 100%;
  height: 100%;
  transition: transform .4s;

  &:hover {
    transform: scale(1.1);
    transform-origin: 50% 50%;
  }
}

.videoIcon {
  height: 1.8rem;
  width: 1.8rem;
  fill: config.$primary-white;
}

.videoIconContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 4rem;
  width: 4rem;
  background-color: config.$primary-global-red;
  border-radius: 50px;
  padding: 1.1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.date {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: config.$primary-dark-grey-text;
  font-weight: 600;
}

.title {
  text-decoration: none;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: config.$primary-dark-grey-text;
  font-weight: 700;
  margin-top: 1.6rem;
  text-transform: uppercase;
  letter-spacing: -0.25px;

  &:hover {
    color: config.$primary-global-blue;
  }
}

.description {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: config.$primary-dark-grey-text;
  font-weight: 300;
  margin-top: 1.6rem;
  letter-spacing: 0.25px;
  text-decoration: none;
}

.metadata {
  display: flex;
  justify-content: space-between;
  margin-top: 1.6rem;
  align-items: center;
}

.categories {
  font-size: 1.2rem;
  line-height: 2rem;
  color: config.$primary-global-blue;
  font-weight: 600;
}

.categoryLink {
  text-decoration: none;
  color: config.$primary-global-blue;

  &:hover {
    color: config.$primary-dark-grey-text;
  }
}

.saveIcon {
  height:1.8rem;
  width: 1.4rem;
  fill: config.$primary-medium-grey;

  &:hover {
    cursor: pointer;
    fill: config.$primary-global-blue;
  }
}

.likesRepliesContainer {
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    margin-bottom: 4rem;
  }
}


.likes,
.replies {
  font-size: 1.2rem;
  line-height: 1.6rem;
  display: flex;
  align-items: center;
  color: config.$primary-light-grey-text;
}

.likes svg,
.replies svg {
  margin-right: 0.7rem;
  width: 1.6rem;
  height: 1.6rem;
}

.likes svg path,
.replies svg path {
  fill: config.$primary-light-grey-text;
}

.likes,
.replies {
  margin: 0 3rem 0 0;
}

// .header {
//   flex: 0 1 auto;
//   font-size: 3.2rem;
//   line-height: 3.2rem;
//   color: config.$primary-dark-grey-text;
//   text-transform: uppercase;
//   margin-right: 2rem;
//   font-weight: 700;
// }