@use '../../styles/config' as config;

.container {
    background-color: config.$primary-global-blue;

    @include config.desktop-view {
        height: 36.8rem;
    }
}

.wrapper {
    display: flex;
    position: relative;
    
    @include config.desktop-view {
        padding: 6.4rem 0;
    }

    @include config.mobile-view {
        flex-direction: column;
        padding: 2rem 0;
    }
}

.column {
    position: relative;
    
    @include config.desktop-view {
        flex: 1 1 auto;
    }

    @include config.mobile-view {
        padding: 2rem 0;

        &:last-child {
            border-top: 1px solid rgb(216, 217, 217);
            border-top: 1px solid rgba(216, 217, 217, 0.2);
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
        }
    }
}

.logo {
    width: 228px;
    height: 61px;
}

.copyright {
    background-color: config.$primary-overlay-blue;
    font-size: 1.2rem;
    line-height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    color: config.$primary-white;

    @include config.mobile-view {
        font-size: 1rem;
        line-height: 2.4rem;
    }
}