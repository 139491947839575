@use '../styles/config' as config;

.container {
    padding-top: 4rem;
}

.wrapper {
    display: flex;
    margin-bottom: 8rem;

    @include config.mobile-view {
        flex-direction: column;
    }
}

.main {
    width: 100%;
    margin-right: 4rem;

    @include config.mobile-view {
        margin-right: 0;
    }
}

.rightRail {
    width: 39.3rem;
    height: 100%;

    @include config.mobile-view {
        width: 100%;
        margin-top: 4rem;
    }
}