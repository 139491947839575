@use '../../styles/config' as config;

.container {
    display: block;
    transition: top 0.3s ease;
    z-index: 5;

    @include config.desktop-view {
        position: absolute;
        min-width: 26rem;
        top: 10rem;
        left: 0;

        &.scrolled {
            top: 6.4rem;
        }
    }

    @include config.mobile-view {
        width: 99%;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    list-style: none;
    background-color: config.$primary-overlay-blue;
}

.option {
    transition: background-color 0.3s;

    & a,
    & a:link,
    & a:visited {
        font-size: 1.4rem;
        text-decoration: none;
        color: config.$primary-white;

        display: flex;
        align-items: center;

        @include config.desktop-view {
            padding: 1.35rem 2rem;
        }

        @include config.mobile-view {
            padding: 0.75rem 2rem;
        }
    }

    @include config.desktop-view {
        &:first-child a {
            padding-top: 2.75rem;
        }
    
        &:last-child a {
            padding-bottom: 2.75rem;
        }
    }

    @include config.mobile-view {
        &:first-child a {
            padding-top: 2.75rem;
        }
    
        &:last-child a {
            padding-bottom: 2.75rem;
        }
    }


    &:hover {
        background-color: config.$primary-global-blue;
    }
}