@use '../../styles/config' as config;

.container {
    width: 34.5rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 4px 1px rgba(0,0,0,0.1);

    @media (max-width: 1200px) {
        width: 100%;
    }
}

.mediaWrapper {
    width: 100%;
    overflow: hidden;
}

.image {
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: transform 1.5s;

    &:hover {
        transform: scale(1.1);
        transform-origin: 50% 50%;
    }
}

.text {
    padding: 2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0.25px;

    & a {
        color: config.$primary-global-blue;
        text-decoration: none;
    }
}