@use './colors' as colors;

/////////////////////////////////
// LAYOUT MEDIA QUERIES
$breakpoint-desktop: 1200px;
$breakpoint-mobile: 1199px;

@mixin desktop-view {
    @media (min-width: $breakpoint-desktop) {
        @content;
    }
}

@mixin mobile-view {
    @media (max-width: $breakpoint-mobile) {
        @content;
    }
}

/////////////////////////////////
// TOOLTIPS
@mixin top-tooltip {
    white-space: nowrap;
    padding: 0 1rem;

    visibility: hidden;
    width: 100px;
    height: 40px;
    background-color: colors.$primary-light-grey;
    color: colors.$primary-light-grey-text;
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: 0.22px;
    font-weight: 300;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 125%;
    margin-left: -64px;

    opacity: 0;
    transition: opacity 0.3s;

    &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: colors.$primary-light-grey transparent transparent transparent;
    }
}

@mixin left-tooltip {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;

    white-space: nowrap;
    background-color: colors.$primary-light-grey;
    color: colors.$primary-light-grey-text;
    text-align: center;
    padding: 1rem;
    border-radius: 6px;
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: 0.22px;
    font-weight: 300;

    position: absolute;
    z-index: 1;
    top: -15px;
    right: 125%;
}

/////////////////////////////////
// GLOBAL BUTTONS
@mixin button {
    font-family: inherit;
    font-weight: bold;
    font-size: 1.8rem;
    border: 2.5px solid;
    border-radius: 2px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0.5rem 0.5rem rgba(black, 0.2);
    }
}

@mixin redButton($height, $width) {
    @include button;
    background-color: colors.$primary-global-red;
    color: colors.$primary-white;
    border-color: colors.$primary-global-red;
    height: $height;
    width: $width;

    &:hover {
        background-color: #BF1B37;
        border-color: #BF1B37;
    }
}

@mixin whiteButton($height, $width) {
    @include button;
    background-color: colors.$primary-white;
    color: colors.$primary-global-blue;
    border-color: colors.$primary-white;
    height: $height;
    width: $width;

    &:hover {
        background-color: colors.$primary-light-grey;
        border-color: colors.$primary-light-grey;
    }
}

@mixin outlineButton($height, $width) {
    @include button;
    background-color: transparent;
    color: colors.$primary-global-blue;
    border-color: colors.$primary-global-blue;
    height: $height;
    width: $width;

    &:hover {
        background-color: colors.$primary-light-grey;
        border-color: colors.$primary-light-grey;
    }
}