@use '../../styles/config' as config;

.container {
  width: 100%;
}

.header {
  font-size: 3.2rem;
  line-height: 3.2rem;
  text-transform: uppercase;
  margin-bottom: 4rem;
}

.resultsWrapper {
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.gridWrapper {
  flex: 0 93.6rem;
}

.range {
  font-size: 1.4rem;
  line-height: 4rem;
  letter-spacing: .25px;
  font-weight: 600;
  margin-bottom: 1.2rem;
}

.grid {
  display: flex;
  flex-flow: row wrap;
}

.grid div:nth-child(3n) {
  margin-right: 0;
}

.paginationWrapper {
  display: block;
  width: 100%
}

.grid, .filterWrapper, .resultsWrapper {
  @media (max-width: 600px) {
    display: block;
    height: 100%;
  }
}