@use '../../styles/config' as config;

.container {
  position: relative;
  padding: 8rem 0;
  overflow: hidden;

  .header {
    font-size: 3.2rem;
    line-height: 3.2rem;
    text-transform: uppercase;
    color: config.$primary-dark-grey-text;
    font-weight: 700;
    margin-bottom: 4.2rem;
  }

  :global(.swiper-button-prev) {
    top: 42%;
    height: 9rem;
    width: 4.5rem;
    border-radius: 0.4rem;
    color: white;
    background-color: config.$primary-global-blue;

    .chevron svg path {
      fill: white;
    }

    &:after {
      content: none;
    }
  }

  :global(.swiper-button-next) {
    top: 42%;
    height: 9rem;
    width: 4.5rem;
    border-radius: 0.4rem;
    color: white;
    background-color: config.$primary-global-blue;

    &:after {
      content: none;
    }
  }

  .chevron {
    width: 20px;
    path {
      fill: white;
    }
  }
}

.contentWrapper {
  position: relative;
}

.swiperContainer {
  width: 90%;
  padding-bottom: 8rem !important;
  height: 34.5rem !important;

  :global(.swiper-pagination-bullet-active) {
    background-color: config.$primary-global-blue;
  }
}

@media (max-width: 991px) {
  .swiperContainer {
    width: 87%;
  }
}

@media (max-width: 767px) {
  .swiperContainer {
    width: 100%;
    overflow: visible;
  }

  .swiperSlide {
    width: 17rem !important;
    margin-right: 5.4rem;
  }

  :global(.swiper-button-prev) {
    display: none !important;
  }

  :global(.swiper-button-next) {
    display: none !important;
  }
}
