@use '../../styles/config' as config;

.container {
  height: 100%;
  width: 100%;
  overflow: hidden;

  @include config.mobile-view {
    margin-bottom: 8rem;
  }
}

.swiperContainer {
  overflow: visible !important;
}

// .swiperWrapper {
//   overflow: visible !important;
// }

// .swiperWrapper > div:first-child {
//   position: relative;
//   top: 51.5rem !important;
// }

.swiperSlide {
  text-decoration: none;

  @include config.mobile-view {
    width: 28.4rem !important;
    margin-right: 2.4rem !important;
  }
}

.display {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.title {
  font-size: 3.2rem;
  line-height: 3.2rem;
  text-transform: uppercase;
  margin-bottom: 4rem;
}