@use '../../styles/config' as config;

.container {
  width: 28.4rem;
  margin-right: 4rem;

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 4rem;
    margin-right: 0;
  }
}

.header {
  @media (max-width: 600px) {
    border-top: .1rem solid #D8D9D9;
  }
}

.header, .formSection {
  border-bottom: .1rem solid #D8D9D9;
}

.filterTitle {
  font-size: 1.8rem;
  line-height: 4rem;
  letter-spacing: 0.25px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: .8rem;
}

.actionBtn {
  height: 3.2rem;
  width: 10rem;
  color: #FFFFFF;
  background-color: config.$primary-overlay-blue;
  border: none;
  border-radius: 1.6rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.4rem;
  text-transform: capitalize;
  margin-bottom: 1.6rem;
}

.actionBtn, .formTitle, .label {
  letter-spacing: .25px;
}

.clearBtn {
  color:#FFFFFF;
  &:hover {
    cursor: pointer;
  }
}

.disabled {
  color: config.$primary-medium-grey;
  font-weight: 500;
  background-color: rgba(228,233,231,0.5);
  &:hover {
    cursor: not-allowed;
  }
}

.collapseBtn {
  border: none;
  outline: none;
  background-color: config.$primary-white;
  float: right;
  cursor: pointer;
}

.btnContainer {
  display: block;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
}

.formSection {
  margin-top: 1.6rem;
  width: 28.4rem;
  padding-bottom: 2.2rem;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.formTitle {
  font-size: 1.6rem;
  line-height: 4rem;
  font-weight: 600;
  text-transform: capitalize;
}

.hide {
  display: none;
}

.hideBtn {
  margin-right: 2.4rem;
}

.input {
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
  font-weight: 300;
}

.label {
  font-size: 1.6rem;
  line-height: 4rem;
  text-transform: capitalize;
  vertical-align: middle;
}

.minusIcon {
  height: .17rem;
  width: 1.3rem;
  margin-top: 2rem;
}

.minusIcon, .chevronDownIcon {
  fill: #4D4F53;
  float: right;
  cursor: pointer;
}

.chevronDownIcon {
  height: 1.3rem;
  width: 1.3rem;
  margin-top: 1.5rem;
}

