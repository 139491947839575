@use '../../styles/config' as config;

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  max-width: 66rem;

  height: fit-content;
  border: none;
  border-radius: 0;
  background-color: config.$primary-white;
  overflow: auto;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);
  z-index: 99;

  padding: 1.8rem 2rem 5.8rem 4rem;

  @media (max-width: 560px) {
    max-width: 38rem;
    height: fit-content;
    padding: 2rem 2rem 4rem 2rem;
  }

  @media (max-height: 450px) {
    padding: 1.8rem 2rem 2rem 4rem;
  }

  @media (max-width: 375px) {
    max-width: 30rem;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.iconWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: config.$primary-dark-grey-text;
  justify-self: flex-end;

  &:hover {
    cursor: pointer;
  }
}

.textWrapper {
  width: 91%;
  margin-top: 3rem;
  margin-bottom: 3.6rem;

  @media (max-width: 560px) {
    margin-bottom: 4rem;
    width: 100%
  }

  @media (max-height: 450px) {
    margin-top: 1rem;
  }
}

.modalHeader {
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin-bottom: 1.5rem;
}

.modalText {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 300;
}

.hide {
  display: none;
}

.buttonWrapper {
  display: flex;
  @media (max-width: 560px) {
    flex-direction: column;
  }
}

.loginRedButton, .loginWhiteButton, .modalHeader {
  text-transform: uppercase;
}

.loginRedButton {
  @include config.redButton($height: 44px, $width: 262px);
  line-height: 2.4rem;
  margin-right: 2.2rem;

  @include config.mobile-view {
    @media (max-width: 560px) {
      margin-bottom: 2.4rem;
    }
  }
}

.loginWhiteButton {
  @include config.outlineButton($height: 4.4rem, $width: 26.2rem);
  line-height: 2.4rem;
}